// store.js
import { configureStore } from "@reduxjs/toolkit";
import loginPageShow from "./reducers/loginPageShow";

const globalStore = configureStore({
  reducer: {
    loginPageShow,
  },
});

export default globalStore;
