import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

import { apiGet } from "../../../../services/api";
import { Link } from "react-router-dom";
import SvgIcon from "../../../common/SvgIcon";

const CourseDiv = ({ logo, name, detail }) => {
  return (
    <div className="flex items-center space-x-4 p-2 ml-[1.4rem] rounded-lg hover:bg-gray-100 cursor-pointer">
      <div className="border rounded-full p-[.2rem] w-[2rem] flex justify-center">
        {SvgIcon(logo, "1.2rem", "1.2rem", "gray")}
      </div>
      <div>
        <h2 className="text-[17px] font-semibold text-gray-900 hover:text-gray-700">
          {name}
        </h2>
        {detail && (
          <div className="flex items-center">
            {SvgIcon("location", "1rem", "1rem", "gray")}
            <p className="text-sm text-gray-500 hover:text-gray-700 pl-[.2rem]">
              {detail}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const SearchHeading = ({ logo, name, detail }) => {
  return (
    <div className="flex items-center space-x-4 p-2 rounded-lg">
      {SvgIcon("search", "1.2rem", "1.2rem", "gray")}
      <div>
        <h2 className="text-[15px] font-semibold text-gray-900 hover:text-gray-700">
          {name}
        </h2>
        <p className="text-[16px] text-gray-500 hover:text-gray-700">
          {detail}
        </p>
      </div>
    </div>
  );
};

const SearchBar = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [query, setQuery] = useState("");
  const [institutes, setInstitutes] = useState([]);
  const [courses, setCourses] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [searchNotfound, setSearchNotfound] = useState({
    text: "Explore Universities, Courses and Blogs",
    icon: "search",
  });

  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (query.length >= 3) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      const timeout = setTimeout(() => {
        fetchData();
        setSearchNotfound({
          text: "Nothing found! Please try other keywords",
          icon: "search_cross",
        });
      }, 1000);

      setDebounceTimeout(timeout);
    } else {
      setCourses([]);
      setInstitutes([]);
      setBlogs([]);
      setSearchNotfound({
        text: "Explore Universities, Courses and Blogs",
        icon: "search",
      });
    }
  }, [query]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await apiGet(`user/search?search=${query}&page=1&limit=15`);
      let institutes = [];
      let courses = [];
      let blogs = [];

      if (res?.data?.institutes) institutes = res.data.institutes;
      if (res?.data?.courses) courses = res.data.courses;
      if (res?.data?.blogs) blogs = res.data.blogs;

      setInstitutes(institutes);
      setCourses(courses);
      setBlogs(blogs);
      // console.log(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  return (
    <div className="relative w-full md:w-[90%] h-[2.5rem]"
      style={{ transform: "translateX(0rem)" }}  // Positive value moves it to the right
    >

      {isFocused && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsFocused(false)}
        ></div>
      )}
      <div className="relative z-50 flex items-center rounded-lg bg-gray-100 border border-gray-300">
        <div className="pl-2 flex">
          <SearchIcon htmlColor="rgb(170,170,170)" />
        </div>
        <input
          type="text"
          placeholder="Search..."
          className="w-full h-10 text-base text-gray-700 pl-2 pr-2 border-none bg-transparent focus:outline-none"
          onFocus={() => setIsFocused(true)}
          // onBlur={() => setIsFocused(false)}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      {isFocused && (
        <div className="absolute top-full left-0 right-0 mt-2 p-4 max-h-[35rem] min-h-[10rem] overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg z-50">
          {loading ? (
            <div className="flex justify-center">
              {SvgIcon("loading", "2rem", "2rem", "green")}
            </div>
          ) : (
            <>
              {institutes?.length === 0 &&
                courses?.length === 0 &&
                blogs?.length === 0 ? (
                <div className="flex flex-col items-center justify-center ">
                  {SvgIcon(
                    searchNotfound.icon,
                    "3rem",
                    "3rem",
                    "rgb(3,163,137)"
                  )}
                  <p className="text-[18px] text-gray-500">
                    {searchNotfound.text}
                  </p>
                </div>
              ) : (
                <>
                  {institutes?.length === 0 ? (
                    <div></div>
                  ) : (
                    <div className="border-b-[2px] pb-[0.5rem]">
                      <SearchHeading
                        name={`${query}`}
                        detail="Search Result in University"
                      />
                      {institutes?.map((d, i) => (
                        <Link
                          to={`/university/${d.slug}`}
                          target="_blank"
                          className="block"
                          key={i}
                        >
                          <CourseDiv
                            logo={"scholar"}
                            name={d?.name}
                            detail={d?.country}
                          />
                        </Link>
                      ))}
                    </div>
                  )}
                  {courses?.length === 0 ? (
                    <div></div>
                  ) : (
                    <div className="border-b-[2px] ">
                      <SearchHeading
                        name={`${query}`}
                        detail="Search Result in Course"
                      />

                      {courses?.map((d, i) => (
                        <Link
                          to={`/course/${d?.slug}`}
                          key={i}
                          className="block"
                          target="_blank"
                        >
                          <CourseDiv
                            logo={"book"}
                            name={d?.name}
                            detail={d?.country}
                          />
                        </Link>
                      ))}
                    </div>
                  )}
                  {blogs?.length === 0 ? (
                    <div></div>
                  ) : (
                    <div className="border-b-[2px] ">
                      <SearchHeading
                        name={`${query}`}
                        detail="Search Result in Blog"
                      />
                      {blogs?.map((d, i) => (
                        <Link
                          to={`/blog/${d?.slug}`}
                          key={i}
                          className="block"
                          target="_blank"
                        >
                          <CourseDiv
                            key={i}
                            logo={"blog"}
                            name={d.title}
                          // detail={d?.country}
                          />
                        </Link>
                      ))}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
