import "./Footer.css";
// import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-information">
        <div className="footer-item footer-item-1">
          <h2>Flcs Pvt Ltd</h2>
          <p>
            Dedicated to revolutionizing the study abroad experience through
            extensive research in curriculum development, student engagement,
            and promoting flexible education options.
          </p>
          <div className="footer-social-icons">
            {/* <div>
              <FacebookOutlinedIcon />
            </div> */}
            <div>
              <Link
                to="https://www.instagram.com/kulwant_in_italy"
                target="_blank"
              >
                <InstagramIcon style={{ color: "white", cursor: "pointer" }} />
              </Link>
            </div>
            <div>
              <Link
                to="https://www.youtube.com/@livinginitalykulwant"
                target="_blank"
              >
                <YouTubeIcon style={{ color: "white", cursor: "pointer" }} />{" "}
              </Link>
            </div>
            <div>
              <Link
                to="https://www.linkedin.com/in/kulwantsinghrathore/"
                target="_blank"
              >
                <LinkedInIcon style={{ color: "white", cursor: "pointer" }} />
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-item footer-item-2">
          <h3>Resources</h3>
          <ul>
            {/* <li>
              <div className="red-rectangle"></div>Instructor/Student Profile
            </li> */}
            <li>
              <div className="red-rectangle"></div>
              <Link to="/explore-universities">Universities</Link>
            </li>
            <li>
              <div className="red-rectangle"></div>
              <Link to="/explore-courses">Courses</Link>
            </li>
            <li>
              <div className="red-rectangle"></div>
              <Link to="">Terms & Conditions</Link>
            </li>
          </ul>
        </div>
        <div className="footer-item footer-item-3">
          <h3>Explore</h3>
          <ul>
            <li>
              <div className="red-rectangle"></div>
              <Link to="/">Home</Link>
            </li>
            <li>
              <div className="red-rectangle"></div>
              <Link to="/blogs">Blog</Link>
            </li>
            <li>
              <div className="red-rectangle"></div>
              <Link to="">Privacy Policy</Link>
            </li>
            <li>
              <div className="red-rectangle"></div>
              <Link to="/about-us">About us</Link>
            </li>
          </ul>
        </div>
        <div className="footer-item footer-item-4">
          <h3>Address</h3>
          <ul>
            <li>
              <div style={{ display: "flex" }}>
                <LocationOnOutlinedIcon
                  style={{ marginRight: "5px" }}
                  fontSize=""
                />
                <div>
                  262308 Tanakpur Road, Opposite Police Station, Majhola
                  (U.S.Nagar), Uttarakhand, India
                </div>
              </div>
            </li>
            <li>
              <PhoneOutlinedIcon style={{ marginRight: "5px" }} fontSize="" />
              <div>
                <Link to="tel:919639036869">+91-963-903-6869</Link>
              </div>
            </li>
            <li>
              <EmailOutlinedIcon style={{ marginRight: "8px" }} fontSize="" />
              <div>
                <Link to="mailto:services@flcs.in"> services@flcs.in</Link>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer-item-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3495.70918597232!2d79.886364!3d28.817739000000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a04500147f80e7%3A0x8fac65aaf132fa22!2sFLCS%20Study%20Abroad%20Consultancy!5e0!3m2!1sen!2sin!4v1737203937059!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ borderRadius: 5}}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="footer-copyright">
        <p>
          &copy;<span id="currentYear"></span>Copyright{" "}
          {new Date().getFullYear()}. All rights reserved By{" "}
          <span style={{ color: "red" }}>Flcs Pvt Ltd</span>
        </p>
        <p>Privacy Policy Terms & Conditions</p>
      </div>
    </div>
  );
};

export default Footer;
