// reducers/counterReducer.js
import { createSlice } from "@reduxjs/toolkit";

const loginPageShowSlice = createSlice({
  name: "loginPageShow",
  initialState: {
    isOpen: false,
    login: true,
    user: JSON.parse(localStorage.getItem("user")),
  },
  reducers: {
    setLoginPageShow: (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.login = action.payload.login;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setLoginPageShow, setUser } = loginPageShowSlice.actions;
export default loginPageShowSlice.reducer;
