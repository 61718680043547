import React, { Suspense, lazy, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from "./components/specific/ScrollToTop";

import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import ReactLoading from "react-loading";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
// import { Home } from "@mui/icons-material";

import logo from "./assets/images/flcs_logo.png";

import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // Import the WhatsApp icon from Material-UI
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";

const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const CoursesPage = lazy(() => import("./pages/CoursesPage/CoursesPage"));
const UniversitiesPage = lazy(() =>
import("./pages/UniversitiesPage/UniversitiesPage")
);
const ShowUniversity = lazy(() =>
  import("./pages/ShowUniversityPage/ShowUniversity")
);
const ShowCourse = lazy(() => import("./pages/ShowCoursePage/ShowCourse"));
const DashboardPage = lazy(() => import("./pages/DashboardPage/DashboardPage"));
const BlogsPage = lazy(() => import("./pages/BlogsPage/BlogsPage"));
const ShowBlog = lazy(() => import("./pages/ShowBlog/ShowBlog"));
const AboutUsPage = lazy(() => import("./pages/AboutUs/AboutUs"));
const OurServicesPage = lazy(() => import("./pages/OurServicesPage/OurServicesPage"));
const StudyAbroadPage = lazy(() => import("./pages/StudyAbroadPage/StudyAbroadPage"));
// const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions/TermsAndConditions"));

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black", // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(3,163,137)", // Border color when focused
          },
        },
        notchedOutline: {
          borderColor: "#9E9E9E", // Default border color
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "rgb(3,163,137)", // Label color when focused
          },
        },
      },
    },
  },
});

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div style={{ width: "5rem" }}>
        <img src={logo} alt="" style={{ width: "6rem" }} />
        <ReactLoading
          type="cylon"
          color="rgb(3,163,137)"
          height={18}
          width={80}
        />
      </div>
    </div>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();
  const mainContainerRef = useRef(null);

  useEffect(() => {
    if (mainContainerRef.current) {
      mainContainerRef.current.classList.remove('fade-in');
      mainContainerRef.current.classList.add('fade-out');
      setTimeout(() => {
        mainContainerRef.current.classList.remove('fade-out');
        mainContainerRef.current.classList.add('fade-in');
        mainContainerRef.current.classList.add('visible');
      }, 300); // Delay to trigger CSS transition
    }
  }, [location]);

  return (

    <div ref={mainContainerRef} className="fade-in">
      <Routes location={location}>
        <Route path="/" element={<HomePage />} />
        <Route path="/explore-courses" element={<CoursesPage />} />
        <Route path="/explore-universities" element={<UniversitiesPage />} />
        <Route path="/university/:slug" element={<ShowUniversity />} />
        <Route path="/course/:slug" element={<ShowCourse />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blog/:slug" element={<ShowBlog />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/our-services" element={<OurServicesPage />} />
        <Route path="/study-abroad" element={<StudyAbroadPage />} />
        <Route path="/PrivacyPolicyPage" element={<PrivacyPolicyPage />} />
        {/* <Route path="/TermsAndConditions" element={<TermsAndConditions />} /> */}
      </Routes>
    </div>
  );
};

function App() {
  const phoneNumber = "+919639036869"; // Replace with your WhatsApp phone number

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          <AnimatedRoutes />
        </Suspense>

        {/* WhatsApp Button */}
        <a
          href={`https://wa.me/${phoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#25D366",
            color: "#fff",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            textDecoration: "none",
            zIndex: 1000,
          }}
        >
          <WhatsAppIcon style={{ fontSize: "30px" }} />
        </a>
      </Router>
    </ThemeProvider>
  );
}

export default App;
